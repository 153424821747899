import React from "react";

import { Fade } from "react-reveal";

export default function AboutUs() {
    return (
        <>
            <div className="about-text">
                <Fade delay={350}>
                    <img className="float-left" src="/images/hugs-logo.png" alt='hearts unite to give support logo' />
                </Fade>

                <Fade delay={150}>
                    <p>
                        Hearts Unite to Give Support (HUGS) is a non-profit 501(C)3 organization dedicated to  making a difference in the lives of individuals and families facing challenging times with a focus on assisting those impacted by bleeding disorders.
                        Established in 2018 in Mason, Michigan, HUGS aims to alleviate stress and provide assistance through education, community events, advocacy, and crucial financial aid. We firmly believe that during times of difficulty,
                        a caring and supportive community can be the beacon of hope that guides individuals towards a brighter future.
                    </p>
                </Fade>

                <Fade delay={350}>
                    <p>
                        At Hearts Unite to Give Support, our mission is centered around fostering a sense of unity and support among those we serve.
                        We understand that life can present unexpected challenges, and in response, we have made it our purpose to be a pillar of strength and compassion for our beneficiaries.
                        Through multiple in-person and online events held throughout the year, we create an inclusive and warm environment that brings people together to share experiences, connect, and build enduring relationships.
                    </p>
                </Fade>

                <Fade delay={550}>
                    <p>
                        One of our primary focuses is extending our helping hand to the bleeding disorder community, specifically those facing all types of Hemophilia and related disorders. We have multiple staff members with bleeding disorders and recognize the unique challenges that individuals and families with bleeding disorders endure.
                        It drives our passion to stand by their side and offer the assistance they need.
                        These conditions have a profound impact on the lives of those affected, often requiring ongoing medical attention, financial support, and emotional care. By dedicating our efforts to this community, we aim to raise awareness, provide resources, and advocate for better access to essential healthcare services.
                    </p>
                </Fade>

                <Fade delay={750}>
                    <p>
                        Our CEO Bonnie Culver has more than two decades of experience within the hemophilia community, brings an deep level of understanding and compassion to our cause.
                        Her deep involvement with organizations such as the National Hemophilia Foundation, the Hemophilia Federation of America, The Hemophilia Foundation of Michigan, and the Great Lakes Hemophilia Foundation has inspired her to pay it forward by establishing HUGS.
                        With unwavering commitment, she has made it her mission to meet the needs of individuals with bleeding disorders, providing advocacy, education, and financial assistance.
                    </p>
                </Fade>

                <Fade delay={950}>
                    <p>
                        Additionally at HUGS, we are supported by a dedicated and dynamic Board of Directors,
                        whose passion for our mission drives the organization forward.
                        Comprising individuals with diverse expertise and backgrounds,
                        our board brings a wealth of experience from various industries.
                        Their valuable insights and strategic guidance play a pivotal role in shaping the trajectory of HUGS, ensuring that we continue to make a meaningful impact on the lives of those we serve.
                    </p>
                </Fade>

                <Fade delay={1150}>
                    <p>
                        As a forward-thinking organization, we recognize the importance of leveraging cutting-edge technology to better serve our community.
                        Our recently developed website, built from the ground up using modern technologies such as React, Express, and more, reflects our commitment to staying at the forefront of digital innovation.
                        This user-friendly platform serves as a showcase of how grant funding investments can yield tangible and impactful results.
                        Through intuitive navigation and streamlined processes, we are empowering users to find the assistance they need more efficiently, ensuring that help is just a click away.
                    </p>
                </Fade>

                <Fade delay={1150}>
                    <p>
                        With our proficient Board of Directors and tech-forward approach, HUGS continues to evolve and improve our services, standing tall as a beacon of hope and support for all those in need.
                        And as we continue our journey, HUGS has developed significant ties with healthcare companies, including Paragon Healthcare, Medexus Pharma, Bayer, and others.
                        This partnership allows us to secure vital funding that directly supports our initiatives and furthers our ability to make a positive impact in the lives of those we serve.
                    </p>
                </Fade>

                <Fade delay={1150}>
                    <p>
                        At Hearts Unite to Give Support, we firmly believe that together, we can make a difference. By embracing the bleeding disorder community and providing unwavering support to those in need, we strive to be the source of comfort and hope for every individual we touch. Join us in our mission to create a compassionate and caring world, one heart at a time.
                    </p>
                </Fade>


            </div>

            <div>
                <h1 className="header">Get Involved</h1>
                <div className="about-links">

                    <Fade delay={150}>
                        <div>
                            <form action="/donate">
                                <button action="submit" className="approved-button">Donate</button>
                            </form>
                        </div>
                    </Fade>

                    <Fade delay={300}>
                        <div>
                            <form action="/join">
                                <button action="submit" className="approved-button">Apply to Join</button>
                            </form>
                        </div>
                    </Fade>

                    <Fade delay={300}>
                        <div>
                            <form action="/contact">
                                <button action="submit" className="approved-button">Contact Us</button>
                            </form>
                        </div>
                    </Fade>

                    <Fade delay={300}>
                        <div>
                            <form action="/events">
                                <button action="submit" className="approved-button">View Our Events</button>
                            </form>
                        </div>
                    </Fade>
                </div>
            </div>
        </>
    )
}