import React, { useState, useLayoutEffect } from 'react';
import axios from 'axios';

import { InputText } from 'primereact/inputtext';

import ApplicationEdit from './ApplicationEdit';
import LegacyApplicationControl from './LegacyApplicationControl';
import ApplicaitonPreview from './ApplicationPreview';

import { Fade } from 'react-reveal';

axios.defaults.withCredentials = true;

const nothing = 0;
const pending = 1;
const approved = 2;
const denied = 3;
const all = 4;
const search = 5;
const legacy = 6;

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default function ApplicationControl(props) {
    const [applications, setApplications] = useState(props.applications);
    const [legacyApplications, setLegacyApplications] = useState(props.legacyApplications);
    const [width, height] = useWindowSize();
    const [showing, setShowing] = useState(nothing);
    const [opened, setOpened] = useState("");
    const [activeApplication, setActiveApplication] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [userInput, setUserInput] = useState("");
    const [editing, setEditing] = useState(false);


    const onPendingClick = () => {
        if (showing !== pending) {
            setOpened("");
            setShowing(pending);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onApprovedClick = () => {
        if (showing !== approved) {
            setOpened("");
            setShowing(approved);
        } else {
            setOpened("");
            setShowing(nothing);
        }

    }

    const onDeniedClick = () => {
        if (showing !== denied) {
            setOpened("");
            setShowing(denied);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onAllClick = () => {
        if (showing !== all) {
            setOpened("");
            setShowing(all);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onLegacyClick = () => {
        if (showing !== legacy) {
            setOpened("");
            setShowing(legacy);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onSearchClick = () => {
        if (showing !== search) {
            setOpened("");
            setShowing(search);
        } else {
            setOpened("");
            setShowing(nothing);
        }
    }

    const onApplicationClick = (token) => {
        setOpened(token);
    }

    const onViewFullClick = (application) => {
        setOpened("");
        setActiveApplication(application);
        setShowing(nothing);
        setEditing(true);
    }

    // Update Applications
    const updateApplications = (newApplication) => {
        // Update Parent State
        props.setApplications((prevApplications) =>
            prevApplications.map((application) =>
                application.token === newApplication.token ? newApplication : application
            )
        );

        // Update Local State
        setApplications((prevApplications) =>
            prevApplications.map((application) =>
                application.token === newApplication.token ? newApplication : application
            )
        );
    };

    // Update Applications
    const updateLocalLegacyApplications = (newApps) => {
        // Update Parent State
        props.setLegacyApplications(newApps);

        // Update Local State
        setLegacyApplications(newApps);
    };


    // Search Handling

    const containsApplication = (application) => {
        for (var i = 0; i < searchResults.length; i++) {
            if (searchResults[i] === application) {
                return true;
            }
        }

        return false;
    }

    const onSearchInput = (event) => {
        // Local Variable to Protect Against Async
        const input = event.target.value.toLowerCase();
        var localResults = searchResults;
        // Update Info
        setUserInput(event.target.value);

        if (input.length > 0) {
            applications.forEach(application => {
                const fullName = application.firstName.toLowerCase() + " " + application.lastName.toLowerCase();
                if (fullName.includes(input) ||
                    application.token.toLowerCase().includes(input) ||
                    application.email.toLowerCase().includes(input)) {
                    if (!containsApplication(application)) {
                        localResults.push(application);
                    }
                } else {
                    localResults = localResults.filter(e => e !== application);
                }
            });
            setSearchResults(localResults);
        } else {
            setSearchResults([]);
        }

    }


    const renderControl = () => {
        if (width <= 1200) {
            return (
                <>
                    <div className='mobile-control'>
                        <button className='pending-button'
                            onClick={onPendingClick}>
                            {width > 680 ? "View Pending" : "Pending"}
                        </button>
                        <button className='approved-button'
                            onClick={onApprovedClick}>
                            {width > 680 ? "View Approved" : "Approved"}
                        </button>

                        <button className='denied-button'
                            onClick={onDeniedClick}>
                            {width > 680 ? "View Denied" : "Denied"}
                        </button>

                        <button className='approved-button'
                            onClick={onLegacyClick}>
                            {width > 680 ? 'Legacy Apps' : 'Legacy Applications'}
                        </button>

                        <button className='all-button'
                            onClick={onAllClick}>
                            View All
                        </button>

                        <button className='search-button'
                            onClick={onSearchClick}>
                            Search
                        </button>
                    </div>
                </>
            )
        }
        return (
            <div className='application-control'>
                <div className='control-items'>
                    <button className='pending-button application-control-button'
                        onClick={onPendingClick}>
                        View Pending
                    </button>
                    <button className='approved-button application-control-button'
                        onClick={onApprovedClick}>
                        View Approved
                    </button>

                    <button className='denied-button application-control-button'
                        onClick={onDeniedClick}>
                        View Denied
                    </button>

                    <button className='all-button application-control-button'
                        onClick={onAllClick}>
                        View All
                    </button>
                </div>

                <div className='application-control-two-button-section'>
                    <button className='approved-button application-control-half-section-button'
                        onClick={onLegacyClick}>
                        Legacy Applications
                    </button>

                    <button className='search-button application-control-half-section-button'
                        onClick={onSearchClick}>
                        Search
                    </button>
                </div>


            </div>
        )
    }

    // Changes the value of 'opened' to the current clicked item, revealing all information about the application
    const renderApplicationItem = (item) => {
        if (opened === item.token) {
            return (
                <li className="application-container"
                    key={item.token}>
                    <button className={item.status === "Approved" ? "application approved" :
                        item.status === "Pending" ? "application pending" :
                            item.status === "Denied" ? "application denied" : "application"}
                        onClick={() => onApplicationClick('')}>
                        <i class="fa-solid fa-angle-down" />
                        {width > 880 ?
                            " Application for " + item.firstName + " " + item.lastName + " - " + item.token + " | Submitted on - " + item.issuedOn + " | Status - " + item.status
                            : "Application " + item.token + " | Status - " + item.status} </button>
                    <ApplicaitonPreview firstName={item.firstName} lastName={item.lastName}
                        gender={item.gender} birthday={item.birthday} email={item.email}
                        phone={item.phone} type={item.type} method={item.method} amount={item.amount}
                        status={item.status} details={item.details}/>
                    <div>
                        <button className='view-full-button approved-button'
                            onClick={() => onViewFullClick(item)}
                        >{item.status === "Pending" ? "View Full Details / Respond" :
                            "View Full Details / Change Decision"}</button>
                    </div>

                </li>
            )
        } else {
            return (
                <li className='application-container' key={item.token}>
                    <button className={item.status === "Approved" ? "application approved" :
                        item.status === "Pending" ? "application pending" :
                            item.status === "Denied" ? "application denied" : "application"}
                        onClick={() => onApplicationClick(item.token)}>
                        <i class="fa-solid fa-angle-right" />
                        {width > 880 ?
                            " Application for " + item.firstName + " " + item.lastName + " - " + item.token + " | Submitted on - " + item.issuedOn + " | Status - " + item.status
                            : "Application " + item.token + " | Status - " + item.status}
                    </button>
                </li>
            )
        }
    }

    const renderResults = () => {
        if (userInput.length === 0) {
            return <h2>Start Typing to Get Started!</h2>
        }
        if (userInput.length > 0 && searchResults.length === 0) {
            return <h2>No Result Found</h2>
        } else {
            return (
                <ul className="applications-list">
                    <div className='applications-list-padding'>
                        {searchResults.map((item, index) => {
                            return (
                                <Fade>
                                    {renderApplicationItem(item)}
                                </Fade>
                            )

                        })
                        }
                    </div>
                </ul>
            )
        }
    }


    const renderSelection = () => {
        switch (showing) {
            case (pending):
                return (
                    <>
                        <ul className="applications-list">
                            <div className='applications-list-padding'>
                                {applications.map((item, index) => {
                                    if (item.status === "Pending") {
                                        return (
                                            <Fade>
                                                {renderApplicationItem(item)}
                                            </Fade>
                                        )
                                    }

                                })
                                }
                            </div>
                        </ul>
                    </>
                )

            case (approved):
                return (
                    <>
                        <ul className="applications-list">
                            <div className='applications-list-padding'>
                                {applications.map((item, index) => {
                                    if (item.status === "Approved") {
                                        return (
                                            <Fade>
                                                {renderApplicationItem(item)}
                                            </Fade>
                                        )
                                    }

                                })
                                }
                            </div>
                        </ul>
                    </>
                )

            case (denied):
                return (
                    <>
                        <ul className="applications-list">
                            <div className='applications-list-padding'>
                                {applications.map((item, index) => {
                                    if (item.status === "Denied") {
                                        return (
                                            <Fade>
                                                {renderApplicationItem(item)}
                                            </Fade>
                                        )
                                    }

                                })
                                }
                            </div>
                        </ul>
                    </>
                )

            case (all):
                return (
                    <>
                        <ul className="applications-list">
                            <div className='applications-list-padding'>
                                {applications.map((item, index) => {
                                    return (
                                        <Fade>
                                            {renderApplicationItem(item)}
                                        </Fade>
                                    )

                                })
                                }
                            </div>
                        </ul>
                    </>
                )

            case (search):
                return (
                    <>
                        <div className="application-search">
                            <h1 className="header">Search for Application</h1>

                            <div className="search-input">
                                <InputText value={userInput} onInput={onSearchInput}></InputText>
                            </div>

                            <h1 className='header'>Results</h1>
                            {renderResults()}
                        </div>
                    </>
                )
        }
    }

    // Use conditional instead of switch to ensure the control isn't rendered twice for each type
    if (showing === legacy) {
        return <LegacyApplicationControl setShowing={setShowing} applications={props.legacyApplications}
            updateParent={updateLocalLegacyApplications}
        />
    }

    if (!editing) {
        return (
            <>
                <div className='display-container'>
                    <h1 className='header'>Applications</h1>
                    {renderControl()}
                    {renderSelection()}
                </div>

            </>
        )
    }

    if (editing) {
        // Protecting against the case of someone making it to this
        // point without an active application
        if (activeApplication) {
            return (
                <>
                    <ApplicationEdit application={activeApplication} setEditing={setEditing} updateApplications={updateApplications} />
                </>

            )
        }
    }


}