import React from 'react';
import { SponsorsData } from "./SponsorsData";

import Fade from 'react-reveal/Fade';

import "./SponsorsRowStyles.css";

function SponsorsRow() {

    return (
        <>
            <div className="sponsors-row">
                <h1 className='header'>Affiliated, With Love</h1>
                <div className='top-sponsor-container'>
                    <img className='top-sponsor-image' alt='paragon healthcare logo' src='/images/sponsors/paragon-new.png'
                        url='https://paragonhealthcare.com/'
                    />
                </div>
                <ul className="sponsors-container">
                    {SponsorsData.map((item, index) => {
                        return (
                            <Fade delay={(index + 1) * 200}>
                                <li className="sponsors-item" key={index}>
                                    <a className='sponsor-logo-link' href={item.url}>
                                        <img className="sponsors-image" src={item.source} alt={item.alt} />
                                    </a>
                                </li>
                            </Fade>
                        )
                    })}
                </ul>
            </div>
        </>
    )
}

export default SponsorsRow;